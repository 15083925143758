<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">{{ $t('users_page.users_list') }}</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
        <button class="btn btn-primary shadow-md mr-2">{{ $t('users_page.add_new_user') }}</button>
        <div class="dropdown">
          <button class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300" aria-expanded="false">
            <span class="w-5 h-5 flex items-center justify-center">
              <PlusIcon class="w-4 h-4" />
            </span>
          </button>
          <div class="dropdown-menu w-40">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                <UsersIcon class="w-4 h-4 mr-2" /> {{ $t('users_page.add_group') }}
              </a>
              <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                <MessageCircleIcon class="w-4 h-4 mr-2" /> {{ $t('users_page.send_message') }}
              </a>
            </div>
          </div>
        </div>
        <div class="hidden md:block mx-auto text-gray-600">
          {{ $t('users_page.showing_entries', { posStart: state.pagination.from, posEnd: state.pagination.to, total: state.pagination.total}) }}
        </div>
        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input v-model="searchValue" type="text" maxlength="255" class="form-control w-56 box pr-10 placeholder-theme-13" :placeholder="$t('users_page.search')" @keyup="searchDebounced" />
            <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
          </div>
        </div>
      </div>

      <user-card v-for="user in state.pagination.data" :key="user.id" :name="user.name" :title="user.email" :photo="user.photo"></user-card>

      <!-- BEGIN: Pagination -->
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
        <pagination :pagination="state.pagination" @page="onPage"></pagination>
        <select class="w-20 form-select box mt-3 sm:mt-0" v-model="state.pagination.per_page" @change="onPerPageChange">
          <option>8</option>
          <option>16</option>
          <option>32</option>
        </select>
      </div>
      <!-- END: Pagination -->
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue"
import UserCard from "@/components/UserCard.vue"
import {onMounted, onBeforeMount, reactive, ref} from "vue"
import _ from "lodash"

export default {
  components: {Pagination, UserCard},
  setup(props) {
    const searchValue = ref(null)
    const state = reactive({
      pagination: {
        per_page: 8,
      },
    })

    onBeforeMount(async () => {
      console.log('onBeforeMount')
      await fetchUserList();
    })

    onMounted(() => {
      console.log('onMounted')
    })

    async function fetchUserList(url = null) {
      console.log('fetchUserList')
      const value = searchValue.value === null ? '' : searchValue.value;
      const perPage = state.pagination.per_page;
      let currentUrl = null;

      if (url !== null) {
        currentUrl = '/be/' + url + `&pp=${perPage}`;
      } else if (state.pagination.links !== null) {
        const current_link = _.find(state.pagination.links, {active: true});
        if (current_link) {
          currentUrl = '/be/' + current_link.url + `&pp=${perPage}&s=${value}`;
        }
      }

      if (currentUrl === null) {
        currentUrl = `/be/users?s=${value}&pp=${perPage}`;
      }

      const response = await fetch(currentUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
      state.pagination = await response.json();
    }

    function onPage(link) {
      if (link.url !== null) {
        fetchUserList(link.url);
      }
    }

    function onPerPageChange() {
      fetchUserList();
    }

    function onSearch() {
      fetchUserList()
    }

    let searchDebounced = _.debounce(onSearch, 300)

    return {
      state,
      fetchUserList,
      onPage,
      searchDebounced,
      onPerPageChange,
      searchValue,
    }
  },
}
</script>
