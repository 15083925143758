<template>
  <ul class="pagination">
    <li v-for="link in pagination.links">
      <a class="pagination__link" :class="{'pagination__link--active': link.active}" @click.prevent="$emit('page', link)" :href="link.url">
        <span v-html="link.label"></span>
      </a>
    </li>
  </ul>
</template>

<script>
import {defineComponent} from "vue"

export default defineComponent({
  name: "Pagination",
  props: {
    pagination: {
      type: Object,
      required: true
    }
  },
  setup(props) {

  }
})
</script>
