<template>
  <div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img class="w-6" :src="require(`@/assets/images/logo.svg`)" />
            <span class="text-white text-lg ml-3">
              Харчі<span class="font-medium">УКР</span>
            </span>
          </a>
          <div class="my-auto">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/login_page/vegetables.png`)"
            />
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
              <p v-html="$t('login_page.banner_info')"></p>
            </div>
            <div
              class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500"
            >
              {{$t('login_page.short_info')}}
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <form
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
            @submit.prevent="login"
          >
            <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
              {{  $t('login_page.sign_in') }}
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:text-left text-center">
              <p v-html="$t('login_page.login_info')"></p>
            </div>

            <div class="intro-x mt-8">
              <input
                autocomplete="username"
                type="text"
                class="form-control intro-x login__input form-control py-3 px-4 border-gray-300 block"
                :placeholder="$t('forms.email')"
                :class="{ 'border-theme-6': validation.errors['email'] }"
                v-model="email"
              />
              <div class="text-theme-6 mt-2" v-if="validation.errors['email']">{{ validation.errors['email'][0] }}</div>
              <input
                autocomplete="current-password"
                type="password"
                class="form-control intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                :placeholder="$t('forms.password')"
                :class="{ 'border-theme-6': validation.errors['password'] }"
                v-model="password"
              />
              <div class="text-theme-6 mt-2" v-if="validation.errors['password']">{{ validation.errors['password'][0] }}</div>
            </div>
            <div class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4">
              <div class="flex items-center mr-auto">
                <input
                  id="remember-me"
                  type="checkbox"
                  class="form-check-input border mr-2"
                  v-model="rememberMe"
                />
                <label class="cursor-pointer select-none" for="remember-me">{{$t('login_page.remember_me')}}</label>
              </div>

<!--              <a href="">Forgot Password?</a>-->
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top" @click.prevent="login" :disabled="isLoginIn">
                {{$t('login_page.login')}}
              </button>
<!--              <button-->
<!--                class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top"-->
<!--              >-->
<!--                Sign up-->
<!--              </button>-->
            </div>

<!--            <div-->
<!--              class="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left"-->
<!--            >-->
<!--              By signin up, you agree to our <br />-->
<!--              <a class="text-theme-1 dark:text-theme-10" href=""-->
<!--                >Terms and Conditions</a-->
<!--              >-->
<!--              &-->
<!--              <a class="text-theme-1 dark:text-theme-10" href=""-->
<!--                >Privacy Policy</a-->
<!--              >-->
<!--            </div>-->
          </form>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs} from 'vue'
import {useStore} from "@/store";

export default defineComponent({
  components: {
  },
  setup() {
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })
  },
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
      errorMessage: null,
      validation: {
        message: null,
        errors: [],
      },
      isLoginIn: false,
    }
  },
  methods: {
    async login() {
      this.isLoginIn = true;
      const _password = this.password;
      this.password = null;
      const store = useStore();
      const csrfResponse = await fetch('/be/csrf-cookie', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
      const loginResponse = await fetch('/be/login', {
        method: 'POST',
        redirect: 'manual',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-XSRF-TOKEN': this.$cookies.get('XSRF-TOKEN')
        },
        body: JSON.stringify({email: this.email, password: _password, rememberMe: this.rememberMe})
      });

      if (loginResponse.status === 200 || loginResponse.type === 'opaqueredirect') {
        console.log('OK. SUCCESS')
        await store.dispatch('main/setUser', {authenticated: true})
        await this.$router.push('/')
      } else if (loginResponse.status === 422) {
        this.validation = await loginResponse.json();
      } else {
        console.log('UNKNOWN ERROR.', loginResponse)
      }
      this.isLoginIn = false;
    }
  }
})
</script>
