<template>
  <div class="intro-y col-span-12 md:col-span-6">
  <div class="box">
    <div class="flex flex-col lg:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
      <div class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
        <img
          alt="Icewall Tailwind HTML Admin Template"
          class="rounded-full"
          :src="photo === null ? require(`@/assets/images/profile.jpg`) : photo"
        />
      </div>
      <div class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
        <a href="" class="font-medium">{{ name }}</a>
        <div class="text-gray-600 text-xs mt-0.5">{{ title }}</div>
      </div>
      <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
        <Tippy
          tag="a"
          href=""
          class="w-8 h-8 rounded-full flex items-center justify-center border dark:border-dark-5 ml-2 text-gray-500 zoom-in"
          content="Facebook"
        >
          <FacebookIcon class="w-3 h-3 fill-current"/>
        </Tippy>
        <Tippy
          tag="a"
          href=""
          class="w-8 h-8 rounded-full flex items-center justify-center border dark:border-dark-5 ml-2 text-gray-500 zoom-in"
          content="Twitter"
        >
          <TwitterIcon class="w-3 h-3 fill-current"/>
        </Tippy>
        <Tippy
          tag="a"
          href=""
          class="w-8 h-8 rounded-full flex items-center justify-center border dark:border-dark-5 ml-2 text-gray-500 zoom-in"
          content="Linked In"
        >
          <LinkedinIcon class="w-3 h-3 fill-current"/>
        </Tippy>
      </div>
    </div>
    <div
      class="flex flex-wrap lg:flex-nowrap items-center justify-center p-5"
    >
      <div class="w-full lg:w-1/2 mb-4 lg:mb-0 mr-auto">
        <div class="flex text-gray-600 text-xs">
          <div class="mr-auto">Progress</div>
          <div>20%</div>
        </div>
        <div class="progress h-1 mt-2">
          <div
            class="progress-bar w-1/4 bg-theme-1"
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
      <button class="btn btn-primary py-1 px-2 mr-2">{{ $t('message.message') }}</button>
      <button class="btn btn-outline-secondary py-1 px-2">{{ $t('message.profile') }}</button>
    </div>
  </div>
  </div>
</template>

<script>
import {defineComponent} from "vue"

export default defineComponent({
  name: "UserCard",
  props: {
    photo: {
      type: String,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
})
</script>

<style scoped>

</style>
