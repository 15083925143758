import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import utils from './utils'
import './libs'
import VueCookies from "vue3-cookies";
//https://stackoverflow.com/a/64631506
import {createI18n} from 'vue-i18n';
import {default as lang_ua}  from "./locales/ua.js"
import {default as lang_en}  from "./locales/ua.js"

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  warnHtmlMessage: false,
  locale: "ua",
  fallbackLocale: "ua",
  messages: {
    ua: lang_ua,
    en: lang_en
  }
});

// SASS Theme
import './assets/sass/app.scss'

const app = createApp(App)
  .use(i18n)
  .use(VueCookies, {
    expireTimes: "30d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None"
  })
  .use(store)
  .use(router)

globalComponents(app)
utils(app)

app.mount('#app')
