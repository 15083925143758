
export default
{
  message: {
    message: "Повідомлення",
    profile: "Профіль",
  },
  forms: {
    email: "Пошта",
    password: "Пароль"
  },
  login_page: {
    sign_in: "Вхід",
    login_info: "Сайт знаходиться на стадії тестування. <br/> Реєстрація тимчасово відсутня.",
    remember_me: "Запам'ятати мене",
    login:"Вхід",
    banner_info: "Лише декілька кліків,<br />щоб створити ваш обліковий запис",
    short_info: "Керуйте та шукайте разом з нами",
  },
  users_page: {
    users_list: "Список Користувачів",
    add_new_user: "Додати Нового користувача",
    add_group: "Додати Групу",
    send_message: "Send Message",
    search: "Search...",
    showing_entries: "Показано від {posStart} до {posEnd} з {total} користувачів",
  }
}
